<template>
    <CRow>
          <CToaster :autohide="3000">
      <template v-for="(toast, index) in toasts">
        <CToast
          :key="'toast' + index"
          :show="true"
          :header="toast.title"
        >
          {{toast.message}}
        </CToast>
      </template>
    </CToaster>
    <CCol col="12" xl="12">
        <CCard>
<!--               <CCardHeader>
                Mensajes
              </CCardHeader> -->
              <CCardBody class="ChatGrid">
                <div class="Companies sm-hide">
                <img style="width: 60px;" src='../../assets/sosdenuncia/logo-region.png' class="navbar-brand" alt="región conectada"/>
                </div>

                <ul class="UsersList" :class="step == 2 ? 'sm-hide' : ''">
                <!--   <input class="input-inset" type="text" placeholder="Buscar" v-model="search"> -->
                <li :style="[item.id == currentMessage.id ? {backgroundColor: '#eff5f3'} : '']"  class="UsersList__Item" v-for="item in conversations" :key="item.id" @click="goToMessages(item)">
                  <img class="UsersList__Item__Img" v-if="item?.last_message?.user?.profile?.avatar" :src="item?.last_message?.user?.profile?.avatar" alt="avatar">
                  <img class="UsersList__Item__Img" v-else src="@/assets/imgs/user.png"  alt="avatar">
                  <p class="UsersList__Item__Text">{{item?.profile_name}}</p>
                </li>
                </ul>
                
              <div class="Message" :class="step == 1 ? 'sm-hide' : ''">
                <div 
                  v-if="loading"
                  class="d-flex flex-column justify-content-center align-items-center" style="min-height: 300px;">
                  <CSpinner
                    style="width:4rem;height:4rem;"
                    color="danger"
                  />
                  <p class="text-secondary my-2">Cargando datos...</p>
                </div>

              <Messages :message="currentMessage" :returnToConversations="returnToConversations" />
            </div>
              </CCardBody>
            </CCard>
        </CCol>
    </CRow>
</template>

<script>
import { getConversations } from '../../api/conversations'
import Messages from './Messages.vue'
import moment from "moment";

export default {
  components:{ Messages },
    data () {
        return {
            toasts: [],
            loading: false,
            currentPage: 1,
            total: null,
            pagination: {
                start: 1,
                perPage: 10,
            },
            conversations: [],
            fields: [
                { key: 'profile_name', label: 'Nombre' },
                { key: 'body', label: 'Último Mensaje' },
                { key: 'created_at', label: 'Fecha Último Mensaje' },
                { key: 'status', label: 'Estado Último Mensaje' },
                { key: 'is_freeform_allowed', label: 'Mensajes' },
            ],
            step: 1,
            currentMessage: {}
        }
    },
    methods: {
        getConversationsData() {
            getConversations().then(res => {
                const arr = res.conversations.map(conversation => {
                  const lastmessage_name = !conversation?.last_message?.user?.profile?.name && !conversation?.last_message?.user?.profile?.lastname
                  ? 'Sin Cuenta' : `${conversation?.last_message?.user?.profile?.name} ${conversation?.last_message?.user?.profile?.lastname}`;

                    const obj = {
                        id: conversation?.id,
                        profile_name: conversation?.current_user_name || conversation?.last_message?.info?.profile_name,
                        body: conversation?.last_message?.info?.body,
                        is_freeform_allowed: conversation?.is_freeform_allowed,
                        status: this.messageStatus(conversation?.last_message?.info?.status),
                        lastmessage_name,
                        created_at: moment(conversation?.last_message?.created_at).format("DD-MM-YYYY")
                    }
                    return obj
                })

                this.conversations = arr
            })
        },
        goToMessages(data) {
          this.currentMessage = data
          this.step = 2
        },
        returnToConversations() {
          this.step = 1
          this.getConversationsData()
        },
          messageStatus(status) {
            const states = {
                sent: 'Enviado',
                delivered: 'Recibido',
                read: 'Leído', 
            }
            return states[status] || ''
        }
    },
    mounted () {
        this.getConversationsData();
    },
}
</script>

<style lang="scss" scoped>


.ChatGrid {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-template-rows: 1fr;
  grid-column-gap: 0px;
  grid-row-gap: 0px;
}

.Companies {
  grid-area: 1 / 1 / 2 / 2;
}

.UsersList {
  height: calc(100vh - 150px);
  overflow-y: scroll;
  grid-area: 1 / 2 / 2 / 5;
  background-color: #fff;
  padding-left: 4px;
  padding-right: 4px;
  &__Item {
    display: flex;
    align-items: center;
    gap: 12px;
    padding-bottom: 10px;
    padding-top: 10px;
    cursor: pointer;
    &:hover {
      background-color: #fff;
    }
    &__Img {
      width: 20px;
      border-radius: 50%;
      background-color: #fff;
    }
    &__Text {
      font-weight: 500;
      color: #000;
      margin-bottom: 0;
    }
  }
}

.Message { 
  grid-area: 1 / 5 / 2 / 13; 
}

.sm-hide {
  display: block;
}
@media (max-width: 568px) {
  .sm-hide {
    display: none;
  }
  .UsersList {
    grid-area: 1 / 1 / 2 / 13;
  }
  .Message {
    grid-area: 1 / 1 / 2 / 13;
  }
}


.input-inset{
font-size: 16px;
line-height: 1.5;
background: #FFFFFF;
background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='19' height='19' viewBox='0 0 19 19'><path fill='%23838D99' d='M13.98 12.57a2 2 0 0 1 1.93.52l2.5 2.5a2 2 0 0 1-2.82 2.82l-2.5-2.5a2 2 0 0 1-.52-1.93l-1.38-1.37a7 7 0 1 1 1.42-1.42l1.37 1.38zm-3.37-2.03a5 5 0 1 0-7.08-7.08 5 5 0 0 0 7.08 7.08z'></path></svg>");
background-repeat: no-repeat;
background-position: 10px 10px;
background-size: 20px 20px;
border: 1px solid #C5CBD5;
box-shadow: inset 0 1px 4px 0 rgba(0,0,0,0.20);
border-radius: 8px;
width: 100%;
padding: .5em 1em .5em 2.5em;
margin-bottom: 12px;
margin-top: 4px;
} 

.input-inset::placeholder{
  color: #838D99;
}

.input-inset:focus {
  outline: none;
  border: 1px solid #84A2FA;
}

/* ===== Scrollbar CSS ===== */
  /* Firefox */
  * {
    scrollbar-width: auto;
    scrollbar-color: #8d8b8d #ffffff;
  }

  /* Chrome, Edge, and Safari */
  *::-webkit-scrollbar {
    width: 8px;
  }

  *::-webkit-scrollbar-track {
    background: #ffffff;
  }

  *::-webkit-scrollbar-thumb {
    background-color: #8d8b8d;
    border-radius: 10px;
    border: 3px solid #ffffff;
  }
</style>