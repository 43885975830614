import { endpoints } from './endpoints'
import request from '@/helpers/request'

export function getConversations() {
	return new Promise((resolve, reject) => {
		request({
			url: endpoints.getConversations,
			method: 'get',
		})
			.then(async function (response) {
				resolve(response)
			}).catch(function (error) {
				reject(error)
			})
		})
	}

export function getConversationMessages(data) {
	return new Promise((resolve, reject) => {
		request({
			url: endpoints.getConversationMessages,
			method: 'get',
            params: data
		})
			.then(async function (response) {
				resolve(response)
			}).catch(function (error) {
				reject(error)
			})
		})
	}

export function sendFreeFormMessageToUser(data) {
	return new Promise((resolve, reject) => {
		request({
			url: endpoints.sendFreeFormMessageToUser,
			method: 'post',
            data
		})
			.then(async function (response) {
				resolve(response)
			}).catch(function (error) {
				reject(error)
			})
		})
	}

export function requestConversationToUser(data) {
	return new Promise((resolve, reject) => {
		request({
			url: endpoints.requestConversationToUser,
			method: 'post',
            data
		})
			.then(async function (response) {
				resolve(response)
			}).catch(function (error) {
				reject(error)
			})
		})
	}

export function getMessageMedia(data) {
	return new Promise((resolve, reject) => {
		request({
			url: endpoints.getMessageMedia,
			method: 'get',
            params: data
		})
			.then(async function (response) {
				resolve(response)
			}).catch(function (error) {
				reject(error)
			})
		})
	}