<template>
        <div>
        <CToaster :autohide="3000">
            <template v-for="(toast, index) in toasts">
            <CToast
            :key="'toast' + index"
            :show="true"
            :header="toast.title"
            >
            {{toast.message}}
        </CToast>
        </template>
        </CToaster>
            <CCard style="height: calc(100vh - 150px);max-width: 1800px; margin: 0 auto;">
            <CCardHeader style="display:flex; gap: 20px; align-items: center; font-weight: 600; font-size: 16px;">
                <CButton class="hide-md" color="primary" @click="returnToConversations">Volver</CButton>
                <p style="margin: 0;">{{message.profile_name}}</p>
            </CCardHeader>
        <CCardBody style=" overflow-y: scroll; padding-bottom: 8px; scroll-behavior: smooth;" id="chat">

            <div v-if="!loader">
            <div v-for="message in messages" :key="message.id" :class="message?.info?.status == 'received' ? 'messageContainer' : 'messageContainer messageContainer--send'">
                <p style="margin-bottom: 4px;"><span style="font-weight: 600;">{{message?.info?.profile_name}}</span> - {{message?.created_at | filterDate}}</p>
                <div :class="message?.info?.status == 'received' ? 'message' : 'message message--send'">
                    <div v-if="message.info.body">
                        {{message.info.body}}
                    </div>
                    <div v-else>
                        <div v-if="!medias.length" @click="getMedia(message?.info?.message_sid)"  class="multimedia-text">
                            Ver Contenido Multimedia
                        </div>
                        <div v-else>
                            <div div v-for="(media, i) in medias" :key="i" style="display: flex; flex-wrap: wrap; gap: 4px;">
                                <img @click="selectMedia(media)" v-if="media.contentType.includes('image') && !loaderMedia" style="width: 100px; height: 100px; cursor:pointer;" :src="media.url">
                                <audio controls v-if="media.contentType.includes('audio') && !loaderMedia">
                                    <source :src="media.url" :type="media.contentType">
                                    Your browser does not support the audio element.
                                </audio>
                                <video @click="selectMedia(media)" :src="media.url" v-if="media.contentType.includes('video') && !loaderMedia"></video>
                                <div v-if="loaderMedia">
                                    <CSpinner color="primary" style="width:1rem;height:1rem;" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {{messageStatus(message?.info?.status)}}
            </div>
            </div>
            <div style="display: flex; justify-content: center; align-items: center; gap: 8px;" v-else>
                <p>Cargando Mensajes</p>
                <div class="loader"></div>
            </div>
            <div style="display: flex; justify-content: center; align-items: center; gap: 8px; flex-direction: column; height:100%;" v-if="!loader && !messages.length">
                <img style="width: 100px;" src="../../assets/icons/022-conversation.png" alt="chat">
                <h4>Te damos la bienvenida a los chats</h4>
                <p>Si seleccionas un chat de la izquierda, la conversación aparecerá aquí.</p>
            </div>

        </CCardBody>
            <div style="margin-top: auto;" v-if="messages.length">
                <CRow v-if="message?.is_freeform_allowed">
                    <CCol col="10" style="padding: 20px;">
                        <CInput v-model="body" placeholder="Mensaje" style="margin-bottom: 0; margin-top:12px;"/>
                    </CCol>
                    <CCol col="2" style="padding-left: 0; padding-top: 20px;">
                        <CButton style="padding-left: 0;" :disabled="!body.length" @click="sendMessages">
                        <img style="transform: rotate(40deg); " src="../../assets/icons/svg/send.svg" alt="send">
                        </CButton>
                    </CCol>
                </CRow>
                <div v-else style="border-top: 1px solid #EBEDEF; padding: 20px;">
                    <CButton :disabled="requestSent" @click="modalRequest = true;" shape="pill"  color="primary">
                        {{requestSent ? 'SOLICITUD ENVIADA' : 'SOLICITAR INICIO DE MENSAJERÍA'}}
                    </CButton>
                </div>
            </div>
        </CCard>


<!--     MODAL ENVIAR SOLICITUD -->
    <CModal
    class="modal-delete"
      style="max-width: 500px !important"
      centered
      title="Enviar Solicitud"
      color="warning"
      :show.sync="modalRequest"
      size="sm"
    >
      <p>Enviar solicitud de mensajería a {{name}}</p>
      <div slot="footer" class="w-100">
        <CButton
          class="mr-2"
          color="primary"
          variant="outline"
          square
          @click="requestStartChat"
        >
          Enviar
        </CButton>
        <CButton
          color="secondary"
          variant="outline"
          square
          @click="() => (modalRequest = !modalRequest)"
        >
          Cancelar
        </CButton>
      </div>
    </CModal>


<!--     MODAL CONTENIDO MULTIMEDIA -->
    <CModal centered color="warning" :show.sync="modalMedia">
        <div>
            <img v-if="selectedMedia?.contentType?.includes('image')" :src="selectedMedia?.url">
            <video v-if="selectedMedia?.contentType?.includes('video')" :src="selectedMedia?.url"></video>
        </div>
    </CModal>
    </div>
</template>

<script>
import moment from "moment";
import { getConversationMessages, sendFreeFormMessageToUser, requestConversationToUser, getMessageMedia } from '../../api/conversations'

export default {
    props: ['message', 'returnToConversations'],
    data () {
        return {
            messages: [],
            body: '',
            wa_id: null,
            
            toasts: [],
            loader: true,
            modalRequest: false,
            disableRequestBtn: false,

            medias: [],
            selectedMedia: '',
            modalMedia: false,
            loaderMedia: false,
            name: ''
        }
    },
    computed: {
        requestSent() {
            const lastMessage = this.messages[this.messages.length - 1]
            const requestDate = moment(lastMessage?.created_at)
            const daysPassed = moment().diff(requestDate, 'days')
            
            return lastMessage?.message_type == 'admin_start' && daysPassed < 1 ? true : false;
        }
    },
    methods: {
        getMessages() {
            this.loader = true;
            const data = {conversation_id: this.message.id}
            getConversationMessages(data).then(res => {
                this.messages = res.messages
                this.wa_id =  res.messages.find(message => message?.info?.status == 'received').info?.wa_id
                this.name =  res.messages.find(message => message?.info?.status == 'received').info?.profile_name
                this.loader = false;
                setTimeout(() => {
                    const chat = document.getElementById('chat');
                    chat.scrollTop = chat.scrollHeight
                }, 100);
            }).catch(e => {
                this.loader = false
                console.log(e);
            })
        },
        sendMessages() {
            const data = {
                conversation_id: this.message.id,
                body: this.body,
                to: this.wa_id
            }
            sendFreeFormMessageToUser(data).then(res => {
                this.body = ''
                this.getMessages();
            }).catch(e => {
                console.log(e);
            })
        },
        requestStartChat() {
            const data = {
              conversation_id: this.message.id ,
              to: this.wa_id
            }
            requestConversationToUser(data).then(res => {
                this.getMessages();
                this.toasts.push({
                title: 'Solicitud enviada',
                  })
                  this.modalRequest = false;
                }).catch(e => {
                    this.toasts.push({
                      title: 'Error',
                        })
                  console.log(e);
                })
            },
            messageStatus(status) {
            const states = {
                sent: 'Enviado',
                delivered: 'Recibido',
                read: 'Leído', 
            }
            return states[status] || ''
        },
        getMedia(message_id) {
            getMessageMedia({message_id}).then(res => {
                this.medias = res.medias
                    this.loaderMedia = true;
                setTimeout(() => {
                    this.loaderMedia = false;
                }, 2000);
            })
        },
        selectMedia(media) {
            this.selectedMedia = media;
            this.modalMedia = true;
        }
    },
    mounted () {
        this.getMessages();
    },
    watch: {
        message(val) {
            this.getMessages();
        }
    },
    filters: {
        filterDate: function (date) {
            return moment(date).format("DD-MM-YYYY HH:mm");
        },
    },
}
</script>

<style lang="scss" scoped>
    .message {
        display: flex;
        background-color: #EBEDEF;
        max-width: 400px;
        padding: 12px 8px;
        border-radius: 15px;
        border-top-left-radius: 0;
        display: flex;
        flex-direction: column;
        font-weight:600;
        margin-bottom: 12px;
        &--send {
            align-items: end;
            border-top-left-radius: 15px;
            border-top-right-radius: 0;
        }
    }

    .messageContainer {
        display: flex;
        flex-direction: column;
        &--send {
            align-items: end;
        }
    }
    .loader {
  width: 40px;
  aspect-ratio: 4;
  --_g: no-repeat radial-gradient(circle closest-side,#000 90%,#0000);
  background: 
    var(--_g) 0%   50%,
    var(--_g) 50%  50%,
    var(--_g) 100% 50%;
  background-size: calc(100%/3) 100%;
  animation: l7 1s infinite linear;
}
@keyframes l7 {
    33%{background-size:calc(100%/3) 0%  ,calc(100%/3) 100%,calc(100%/3) 100%}
    50%{background-size:calc(100%/3) 100%,calc(100%/3) 0%  ,calc(100%/3) 100%}
    66%{background-size:calc(100%/3) 100%,calc(100%/3) 100%,calc(100%/3) 0%  }
}

.multimedia-text {
    &:hover {
        cursor: pointer;
        text-decoration: underline;
    }
}

@media (min-width: 568px) {
    .hide-md {
        display: none;
    }
}
</style>